import React from "react";
import { Link } from "react-router-dom";

import "./About.css";

function About() {
  return (
    <div className="about">
      <div className="about__section">
        About
        <div>
          <img
            className="about__image"
            src="/jinandjel.gif"
            alt="Jin and Jel walking"
          />
        </div>
        <p className="about__content">
          Hello from Kittenbean Studios!
          <br />
          <br />
          We are a team of 2 developers, and we are sisters! The project we're
          currently working on is called <br />
          <i>Slime in a Trench Coat: A Cooking Mystery.</i>
          <br />
          <br />
          Our goal is to make the cute, fun, and cozy games we've always craved.
          <br />
          <br />
          Feel free to contact us with any inquiries at{" "}
          <a href="mailto:support@kittenbeanstudios.com">
            support@kittenbeanstudios.com
          </a>
          <br />
          We're also on Bluesky{" "}
          <a href="https://bsky.app/profile/kittenbeanstudios.bsky.social">
            {"(@kittenbeanstudios.bsky.social)"}
          </a>{" "}
          and TikTok{" "}
          <a href="https://www.tiktok.com/@kittenbean.studio">
            {"(@kittenbean.studio)"}
          </a>
          <br />
          <br />
          Thank you for visiting our site!
        </p>
        <div>
          <img
            className="about__image"
            src="/fooditems.png"
            alt="Three food items from the game"
          />
        </div>
      </div>
      <div>
        <Link to="/">
          <button className="about_button">Back to Home</button>
        </Link>
      </div>
    </div>
  );
}

export default About;
