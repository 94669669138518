import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Presskit from "./components/Presskit";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path="presskit" element={<Presskit />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
