import { Link } from "react-router-dom";

import "./Nav.css";

function Nav() {
  return (
    <div className="nav">
      <div className="nav__name">
        <Link className="nav__link" to="/">
          kittenbean studios
        </Link>
      </div>
      <div className="nav__links">
        <Link
          className="nav__link"
          to="https://store.steampowered.com/app/3217720/Slime_in_a_Trench_Coat_A_Cooking_Mystery/"
        >
          Steam Page
        </Link>
        <Link className="nav__link" to="presskit">
          Presskit
        </Link>
        <Link className="nav__link" to="about">
          About
        </Link>
      </div>
    </div>
  );
}

export default Nav;
