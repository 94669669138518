import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <h3>Contact</h3>
      <a className="footer__email" href="mailto:support@kittenbeanstudios.com">
        support@kittenbeanstudios.com
      </a>
      <p>©2024 Kittenbean Studios LLC</p>
    </footer>
  );
}

export default Footer;
